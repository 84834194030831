import React, { Fragment, useState } from 'react'
import { Accordion, Card, Button, Container, Row, Col, NavDropdown, Nav, Navbar, Tabs, Tab } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import classNames from 'classnames'

const Dashboard = () => {
    return (
        <Layout />
    )
}

const Layout = () => {
    const [sideBarStatus, setStatus] = useState(true);
    const toggleSideBar = () => {
        setStatus(!sideBarStatus)
    }
    var sideBarClass = classNames({
        sidebarActive: sideBarStatus, 
        // 'animated slideInLeft': sideBarStatus,
        // 'animated slideOutLeft': !sideBarStatus
    });

    var contentClass = classNames({
        sidebarActive: sideBarStatus, 
        // 'animated slideInLeft': sideBarStatus,
        // 'animated slideOutLeft': !sideBarStatus
    })

    return (
        <div className='wrapper'>
            <nav id='sidebar' className={sideBarClass}>
                <div className='sidebar-header'>
                    <h3>Sidebar</h3>
                </div>
                <div>
                    <h3>
                        Sigo probando
                    </h3>  
                </div>
            </nav>

            <div id='content' className={contentClass}>
                <NavBar toggleSideBar={toggleSideBar}/>
                <Entregas/>
            </div>
        </div>
    )
}

const Entregas = () => {
    const [key, setKey] = useState('home')

    return (
        <Container>
            <h2>Entregas</h2>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
                <Tab eventKey="home" title="Ordenes">
                    <Row >
                        <Col >
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <h6>
                                            Query
                                        </h6>
                                    </div>
                                    <Card >
                                        <p>aqui van las opciones se supone</p>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <h6>
                                            Tus entregas
                                        </h6>
                                    </div>
                                    <Card style={{ width: "100%", height: "70vh" }}>
                                        <EntregasMapa />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="profile" title="Profile">
                    world
                </Tab>
                <Tab eventKey="contact" title="Contact">
                    caleiting
                </Tab>
            </Tabs>
        </Container>
    )
}

const EntregasMapa = () => {
    const defaultProps = {
        center: { lat: 20.6774, lng: -103.3472 },
        zoom: 12
    }

    const handleApiLoaded = (map, maps) => {
        console.log(map)
        console.log(maps)
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: 'AIzaSyAklDFxHo1ihuPXynhNfRRtGaUh5IeZdc8',
                language: 'es'
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
        </GoogleMapReact>
    )
}

// bootstrapURLKeys={{
//     key: 'AIzaSyAklDFxHo1ihuPXynhNfRRtGaUh5IeZdc8',
//     language: 'en'
// }}
// style={{width: '100%', height: '100%', position: 'relative'}}
// defaultCenter={defaultProps.center}
// defaultZoom={defaultProps.zoom}
// yesIWantToUseGoogleMapApiInternals
// onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}

const NavBar = (props) => {
    const {toggleSideBar} = props
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Button onClick={toggleSideBar}>menu</Button>
            <Nav className="mr-auto">
                <NavDropdown title="ProjectName" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <Nav>
                <Nav.Link eventKey={2} href="#memes">
                    User
                    </Nav.Link>
            </Nav>
        </Navbar>
    )
}

const Menu = () => {
    return (
        <Fragment>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Entregas
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Fragment>
                            <Button variant="link" size="md" block>Organiza tus entregas</Button>
                            <Button variant="link" size="md" block>Configuracion de entregas</Button>
                        </Fragment>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Entregas
                </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Button variant="link" size="md" block>Organiza tus entregas</Button>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Fragment>

    )
}

export default Dashboard;