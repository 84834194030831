import React from 'react';
import Dashboard from './components/Dashboard/dashboard'

function App() {
  return (
    <Dashboard className='main'/>
  );
}

export default App;
